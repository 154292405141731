<template>
  <div class="card h-100">
    <h4 class="p-2">
      ธนาคาร
    </h4>
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th class="h6">
            ธนาคาร / ชื่อบัญชี
          </b-th>
          <b-th class="h6">
            ประเภท
          </b-th>
          <b-th class="h6">
            ยอดคงเหลือ
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="agent">
        <b-tr
          v-for="(item) in agent.BankData"
          :key="item.name"
        >
          <b-td>
            <div class="d-flex align-items-center">
              <img
                v-if="item.gateway == 1"
                :src="`/payment/${item.bank_path_photo}`"
                alt=""
                height="25"
                width="25"
                class="rounded-circle mr-50"
              >
              <img
                v-else
                :src="`/bankIcon/${item.bank_path_photo}`"
                alt=""
                height="25"
                width="25"
                class="rounded-circle mr-50"
              >
              <div>
                <h5 class="mb-0">
                  {{ item.acc_number }}
                </h5>
                <small class="mb-0 text-muted">
                  {{ item.acc_name }}
                </small>
              </div>
            </div>
          </b-td>
          <b-td>
            <b-button
              variant="success"
              size="sm"
            >
              ฝาก
            </b-button>
          </b-td>
          <b-td>{{ item.balance ? item.balance: '-' }}</b-td>
        </b-tr>
        <b-tr
          v-if="agent.autowithdraw === 'scb' && agent.auto_wit_scb"
        >
          <b-td>
            <div class="d-flex align-items-center">
              <img
                src="/bankIcon/scb.png"
                alt=""
                height="25"
                width="25"
                class="rounded-circle mr-50"
              >
              <div>
                <h5 class="mb-0">
                  {{ agent.auto_wit_scb.accno }}
                </h5>
                <small class="mb-0 text-muted">
                  {{ agent.auto_wit_scb.accname }}
                </small>
              </div>
            </div>
          </b-td>
          <b-td>
            <b-button
              variant="danger"
              size="sm"
            >
              ถอน
            </b-button>
          </b-td>
          <b-td>{{ Commas(agent.withdraw_scb_balance) }}</b-td>
        </b-tr>
        <b-tr
          v-if="agent.autowithdraw === 'krungsri' && agent.auto_wit_krungsri"
        >
          <b-td>
            <div class="d-flex align-items-center">
              <img
                src="/bankIcon/BAY.png"
                alt=""
                height="25"
                width="25"
                class="rounded-circle mr-50"
              >
              <div>
                <h5 class="mb-0">
                  {{ agent.auto_wit_krungsri.wit_bank3_accname }}
                </h5>
                <small class="mb-0 text-muted">
                  {{ agent.auto_wit_krungsri.wit_bank3_accno }}
                </small>
              </div>
            </div>
          </b-td>
          <b-td>
            <b-button
              variant="danger"
              size="sm"
            >
              ถอน
            </b-button>
          </b-td>
          <b-td>{{ Commas(agent.withdraw_krungsri_balance) }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import {
  BRow, BCol, BTable, BButton,
  BThead,
  BTr,
  BTd,
  BTh,
  BTbody,
  BTableSimple,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BButton,
  },
  props: ['data', 'agent'],
  data() {
    return {
      now: moment.tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss'),
    }
  },
  methods: {
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>
